import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import FormikErrorMessage from "../../../components/FormikValidationMessage";
import FormikMultiselect from "../../../components/FormMultiSelect";
import { getSemesterList } from "../../../store/slice/semesterSlice";
import { getSubjectList } from "../../../store/slice/subjectSlice";
import { useDispatch, useSelector } from "react-redux";
import FormikSelect from "../../../components/NiceSelect";
import { getFileTypeFromS3SignedURL } from "../../../Common/downloadFile";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../../Common/notification";
import {
  getUniversityList,
  select_university,
} from "../../../store/slice/universitySlice";
import uploadToS3, {
  generateRandomFileName,
} from "../../../Common/s3AwsService";
import { editTutor } from "../../../store/slice/authSlice";
import PhoneInputField from "../../../components/MobileNumber";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getLanguageList } from "../../../store/slice/masterSlice";
import ImageCropper from "../../../components/ImageCropper";
import { useTranslation } from "react-i18next";

const TutorEditProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    semester: { data: semesterData },
    subject: { data: subjectData },
    university: { data: universityData },
    master: {
      language: {
        data: { data: languageData },
      },
    },
    auth: { userInfo },
  } = useSelector((state) => state);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const [imageFile, setImageFile] = useState(null);
  const [open, setOpen] = useState(false);

  const isPhoneValid = useCallback(
    (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      } catch (error) {
        return false;
      }
    },
    [phoneUtil]
  );

  const initialValues = useMemo(
    () => {
      return {
        first_name: "",
        last_name: "",
        mobile_number: "",
        wp_mobile_number: "",
        email: "",
        avs_details: "",
        past_experience: "",
        bio: "",
        holder_name: "",
        account_number: "",
        iban: "",
        bic_swift: "",
        city: "",
        country: "",
        postalCode: "",
        identity: "",
        experiance: "",
        tutor_response: "",
        // availble_platform: "",
        outlookEmail: userInfo?.tutor_outlook_email,
        address: "",
        platform: userInfo?.plateform || "",
        zoomLink: userInfo?.zoom_link || "",
        zoomMeetingId: userInfo?.zoom_id || "",
        zoomPassword: userInfo?.zoom_passcode || "",
        googleMeetLink: userInfo?.meet_link || "",
        teamsLink: userInfo?.team_link || "",
        teamsIds: userInfo?.team_id || "",
        teamsPassword: userInfo?.team_passcode || "",
        ...userInfo,
        university: userInfo?.school_university_id
          ? {
              id: userInfo?.school_university_id,
              university_name: userInfo?.school_university_name,
            }
          : {},
        language:
          languageData?.filter((item) =>
            userInfo?.language
              ?.split(",")
              .map((item) => Number(item))
              .includes(item.id)
          ) || [],
        subject: userInfo?.user_subject?.main || [],
        semester: userInfo?.user_semesters?.main || [],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languageData, userInfo]
  );

  const validationSchema = Yup.object({
    first_name: Yup.string().required(t("firstNameRequired")),
    last_name: Yup.string().required(t("lastNameRequired")),
    mobile_number: Yup.string()
      .required(t("phoneNumberRequired"))
      .test("phoneValidation", t("phoneNumberValidation"), (phone) => {
        if (phone) {
          return isPhoneValid(phone);
        } else {
          return true;
        }
      }),
    wp_mobile_number: Yup.string()
      .required(t("waNumberRequired"))
      .test("phoneValidation", t("waNumberValidation"), (phone) => {
        if (phone) {
          return isPhoneValid(phone);
        } else {
          return true;
        }
      }),

    email: Yup.string()
      .email(t("invalidEmail"))
      .required(t("emailIsRequired"))
      .test(
        "emailDomainValidation",
        t("emailDomainValidation"),
        function (value) {
          const { university } = this.parent;
          if (university && university.allow_email) {
            const emailDomain = university.allow_email;
            return value && value.endsWith(emailDomain);
          }
          return true;
        }
      ),
    outlookEmail: Yup.string()
      .email(t("invalidEmail"))
      .required(t("emailIsRequired")),
    university: Yup.object().required(t("pleaseSelectUniversity")),
    semester: Yup.array().of(Yup.object()).min(1, t("semesterValidation")),
    subject: Yup.array()
      .of(Yup.object({ grade: Yup.number().required(t("gradeRequired")) }))
      .min(1, t("subjectsValidation")),
    address: Yup.string().required(t("addressRequired")),
    experiance: Yup.string().required(t("experianceRequired")),
    tutor_response: Yup.string().required(t("tutorResponseTimeRequired")),
    // availble_platform: Yup.string().required("Platform available is required"),
    teach: Yup.string().required(t("teachingAvailabilityRequired")),
    language: Yup.array().of(Yup.object()).min(1, t("languagesRequired")),
    avs_details: Yup.string().required(t("avsRequired")),
    past_experience: Yup.string(),
    bio: Yup.string().required(t("bioRequired")),
    platform: Yup.string().required(t("meetingPlatformRequired")),

    zoomLink: Yup.string().when("platform", {
      is: "zoom",
      then: () => Yup.string().required(t("zoomLinkRequired")),
    }),
    zoomMeetingId: Yup.string().when("platform", {
      is: "zoom",
      then: () => Yup.string().required(t("zoomIdRequired")),
    }),
    zoomPassword: Yup.string().when("platform", {
      is: "zoom",
      then: () => Yup.string().required(t("zoomPasswordRequired")),
    }),

    googleMeetLink: Yup.string().when("platform", {
      is: "googleMeet",
      then: () => Yup.string().required(t("googleMeetLinkRequired")),
    }),

    teamsLink: Yup.string().when("platform", {
      is: "teams",
      then: () => Yup.string().required(t("teamsLinkRequired")),
    }),
    teamsIds: Yup.string().when("platform", {
      is: "teams",
      then: () => Yup.string().required(t("teamsIdRequired")),
    }),
    teamsPassword: Yup.string().when("platform", {
      is: "teams",
      then: () => Yup.string().required(t("teamsPasswordRequired")),
    }),
  });

  const handlePlatformChange = (platform, setFieldValue) => {
    setFieldValue("platform", platform); // Set the selected platform
    // Clear all fields except those relevant to the selected platform
    // if (platform === "zoom") {
    //   setFieldValue("googleMeetLink", "");
    //   setFieldValue("teamsLink", "");
    //   setFieldValue("teamsIds", "");
    //   setFieldValue("teamsPassword", "");
    // } else if (platform === "googleMeet") {
    //   setFieldValue("zoomLink", "");
    //   setFieldValue("zoomMeetingId", "");
    //   setFieldValue("zoomPassword", "");
    //   setFieldValue("teamsLink", "");
    //   setFieldValue("teamsIds", "");
    //   setFieldValue("teamsPassword", "");
    // } else if (platform === "teams") {
    //   setFieldValue("zoomLink", "");
    //   setFieldValue("zoomMeetingId", "");
    //   setFieldValue("zoomPassword", "");
    //   setFieldValue("googleMeetLink", "");
    // }
  };

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const myPromise = new Promise(async (resolve, reject) => {
        const payload = {
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: values?.email,
          tutor_outlook_email: values?.outlookEmail,
          mobile_number: values?.mobile_number,
          wp_mobile_number: values?.wp_mobile_number,
          teach: values?.teach,
          avs_details: values?.avs_details,
          past_experience: values?.past_experience,
          experiance: values?.experiance,
          address: values?.address,
          tutor_response: values?.tutor_response,
          // availble_platform: values?.availble_platform,
          bio: values?.bio,
          school_university_id: values?.university?.id,
          school_university_name: values?.university?.university_name,
          semesters: values?.semester?.map((item) => item.id).join(","),
          subjects: values?.subject?.map((item) => {
            return {
              subject_id: item.id,
              grade: item?.grade || 0,
              subject_language: item?.lang_flag || "en",
            };
          }),
          language: values?.language?.map((item) => item.id)?.join(","),
          zoom_link: values?.zoomLink,
          zoom_id: values?.zoomMeetingId,
          zoom_passcode: values?.zoomPassword,
          meet_link: values?.googleMeetLink,
          team_link: values?.teamsLink,
          team_id: values?.teamsIds,
          team_passcode: values?.teamsPassword,
          plateform: values?.platform,
        };
        if (values?.platform === "zoom"){
          payload.googleMeetLink = "";
          payload.teamsLink = "";
          payload.teamsIds = "";
          payload.teamsPassword = "";
        } else if(values?.platform === "googleMeet"){
          payload.zoomLink = "";
          payload.zoomMeetingId = "";
          payload.zoomPassword = "";
          payload.teamsLink = "";
          payload.teamsIds = "";
          payload.teamsPassword = "";
        } else if (values?.platform === "teams"){
          payload.zoomLink = "";
          payload.zoomMeetingId = "";
          payload.zoomPassword = "";
          payload.googleMeetLink = "";
        }
        if (values?.userPhotoChange) {
          await uploadToS3(
            values?.userPhotoFile,
            `${generateRandomFileName()}.${
              values?.userPhotoFile.type.split("/")[1]
            }`,
            "user"
          ).then((response) => {
            payload.profile_image = response.key;
          });
        }
        if (values?.identityChange) {
          await uploadToS3(
            values?.identityDocumentFile,
            `${generateRandomFileName()}.${
              values?.identityDocumentFile.type.split("/")[1]
            }`,
            "identity"
          ).then((response) => {
            payload.identity = response.key;
          });
        }
        if (values?.transcriptChange) {
          await uploadToS3(
            values?.gradeTranscriptDocumentFile,
            `${generateRandomFileName()}.${
              values?.gradeTranscriptDocumentFile.type.split("/")[1]
            }`,
            "transcript"
          ).then((response) => {
            payload.transcript = response.key;
          });
        }

        dispatch(editTutor(payload)).then((result) => {
          if (result.payload.code === "1") {
            resolve(result.payload);
            dispatch(select_university(values?.university));
            setSubmitting(false);
          } else {
            reject(result.payload);
          }
        });
      });

      showPromiseNotification(myPromise);
    },
    [dispatch]
  );

  useEffect(() => {
    // dispatch(getSubjectList({}));
    dispatch(getUniversityList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSemesterList({
        find_by_university_id: userInfo?.school_university_id,
      })
    );
  }, [dispatch, userInfo?.school_university_id]);

  useEffect(() => {
    dispatch(getLanguageList({}));
  }, [dispatch]);

  useEffect(() => {
    if (
      userInfo?.school_university_id &&
      userInfo?.user_semesters?.main?.length
    ) {
      dispatch(
        getSubjectList({
          university_id: userInfo?.school_university_id,
          semester_ids: userInfo?.user_semesters?.main
            ?.map((item) => item.id)
            .join(","),
        })
      );
    }
  }, [
    dispatch,
    userInfo?.school_university_id,
    userInfo?.user_semesters?.main,
  ]);

  return (
    <>
      <section className="edit-profile-section">
        <div className="page-content">
          <div className="page-content-wrapper">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-6 col-sm-12 col-12 mt-30px">
                <div className="tutor-dash-header">
                  <h2 className="tutor-heading">{t("editProfile")}</h2>
                </div>
              </div>
            </div>
            <div className="edit-profile-main">
              <div className="apply-form">
                <div id="container" className="container-fluid">
                  {/* {(!isLoading ||
                  isSemesterLoading ||
                  isSubjectLoading ||
                  isUniversityLaoding) && <Loader />} */}
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      setFieldValue,
                      errors,
                      submitCount,
                      setFieldError,
                    }) => {
                      const handleSubjectChange = (selectedSubjects) => {
                        const updatedSubjects = selectedSubjects.map(
                          (subject) => ({
                            ...subject,
                            grade: 0, // Initialize grade field for the selected subjects
                          })
                        );
                        setFieldValue("subject", updatedSubjects);
                      };

                      const handleCropComplete = (croppedImage) => {
                        setFieldValue(
                          "profile_image",
                          URL.createObjectURL(croppedImage)
                        );
                        setFieldValue("userPhotoFile", croppedImage);
                        setFieldError("userPhoto", "", false);

                        setFieldValue("userPhotoChange", true);
                      };

                      const handleFileImageChange = (event) => {
                        const selectedFiles = event.target.files;
                        if (selectedFiles.length > 0) {
                          const file = selectedFiles[0];
                          if (file.type.startsWith("image/")) {
                            setImageFile(file);
                            setOpen(true);
                          } else {
                            setFieldValue("userPhotoChange", false);
                            showErrorMessage(t("onlyImageAllowed"));
                          }
                        }
                      };
                      const handleIdentityDocument = (event) => {
                        const selectedFiles = event.target.files;
                        if (selectedFiles.length > 0) {
                          const file = selectedFiles[0];
                          const allowedExtensions = [
                            "jpeg",
                            "jpg",
                            "png",
                            "pdf",
                          ];
                          const fileExtension = file.name
                            .split(".")
                            .pop()
                            .toLowerCase();

                          if (allowedExtensions.includes(fileExtension)) {
                            setFieldValue("identity", file.name);

                            setFieldValue("identityDocumentFile", file);
                            setFieldValue("identityChange", true);
                          } else {
                            setFieldValue("identityChange", false);
                            showErrorMessage(t("selectedDocsAllowed"));
                          }
                        }
                      };
                      const handleGradeTranscriptDocument = (event) => {
                        const selectedFiles = event.target.files;
                        if (selectedFiles.length > 0) {
                          const file = selectedFiles[0];
                          const allowedExtensions = [
                            "jpeg",
                            "jpg",
                            "png",
                            "pdf",
                          ];
                          const fileExtension = file.name
                            .split(".")
                            .pop()
                            .toLowerCase();
                          if (allowedExtensions.includes(fileExtension)) {
                            if (
                              ["jpeg", "jpg", "png"].includes(fileExtension)
                            ) {
                              const imageUrl = URL.createObjectURL(file);
                              setFieldValue("transcript", imageUrl);
                            } else {
                              setFieldValue("transcript", file.name);
                            }
                            setFieldValue("gradeTranscriptDocumentFile", file);
                            setFieldValue("transcriptChange", true);
                          } else {
                            setFieldValue("transcriptChange", false);
                            showErrorMessage(t("selectedDocsAllowed"));
                          }
                        }
                      };
                      return (
                        <>
                          {/* <SectionUseEffect values={values} /> */}
                          {imageFile && (
                            <ImageCropper
                              imageFile={imageFile}
                              onCropComplete={handleCropComplete}
                              open={open}
                              handleClose={() => setOpen(false)}
                            />
                          )}
                          <Form>
                            <div className="step step-1">
                              <h3 className="personal-text border-0">
                                {t("personalInfo")}
                              </h3>
                              <div className="col-12">
                                <div className="apply-user-main">
                                  <div className="apply-user-photo">
                                    <img
                                      src={
                                        values.profile_image ||
                                        process.env.PUBLIC_URL +
                                          "/assets/images/icons/apply-user-photo.svg"
                                      }
                                      className="img-100 border-50"
                                      alt="apply-user-photo"
                                    />
                                    <div className="apply-add-photo">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/camera.svg"
                                        }
                                        alt="camera"
                                        className="border-50"
                                      />
                                      <div className="apply-camera-input">
                                        <input
                                          type="file"
                                          id="profile_image"
                                          name="profile_image"
                                          onChange={handleFileImageChange}
                                          className="form-control-file"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="profile_image"
                                    component="div"
                                    className="error-message text-center mt-3"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label
                                    htmlFor="first_name"
                                    className="form-label"
                                  >
                                    {t("firstName")}
                                  </label>
                                  <div className="position-relative">
                                    <Field
                                      type="text"
                                      className="form-control apply-input"
                                      id="first_name"
                                      name="first_name"
                                    />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/avatar.svg"
                                        }
                                        alt="avatar"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="first_name"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label
                                    htmlFor="last_name"
                                    className="form-label"
                                  >
                                    {t("lastName")}
                                  </label>
                                  <div className="position-relative">
                                    <Field
                                      type="text"
                                      className="form-control apply-input"
                                      id="last_name"
                                      name="last_name"
                                    />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/avatar.svg"
                                        }
                                        alt="avatar"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label
                                    htmlFor="mobile_number"
                                    className="form-label"
                                  >
                                    {t("phoneNumber")}
                                  </label>
                                  <div className="position-relative">
                                    <PhoneInputField name="mobile_number" />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/phone-icon.svg"
                                        }
                                        alt="phone-icon"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="mobile_number"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label
                                    htmlFor="wp_mobile_number"
                                    className="form-label"
                                  >
                                    {t("waNumber")}
                                  </label>
                                  <div className="position-relative">
                                    <PhoneInputField name="wp_mobile_number" />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/phone-icon.svg"
                                        }
                                        alt="phone-icon"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="wp_mobile_number"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label htmlFor="email" className="form-label">
                                    {t("email")}
                                  </label>
                                  <div className="position-relative">
                                    <Field
                                      type="text"
                                      className="form-control apply-input"
                                      id="email"
                                      name="email"
                                    />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/email-icon.svg"
                                        }
                                        alt="email-icon"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label htmlFor="email" className="form-label">
                                    {t("outlookEmail")}
                                  </label>
                                  <div className="position-relative">
                                    <Field
                                      type="text"
                                      className="form-control apply-input"
                                      id="outlookEmail"
                                      name="outlookEmail"
                                    />
                                    <div className="apply-icon">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/email-icon.svg"
                                        }
                                        alt="email-icon"
                                      />
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="outlookEmail"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                  <label
                                    htmlFor="university"
                                    className="form-label"
                                  >
                                    {t("schoolUnviversity")}
                                  </label>
                                  <FormikSelect
                                    id="a-select"
                                    name="university"
                                    placeholder={t("selectYourUniversity")}
                                    className="basic-single height-50 "
                                    disabled={!universityData.length}
                                    options={universityData}
                                    onChange={(value) => {
                                      dispatch(
                                        getSemesterList({
                                          find_by_university_id: value?.id,
                                        })
                                      );
                                      setFieldValue("semester", []);
                                      setFieldValue("subject", []);
                                    }}
                                    getOptionLabel={(item) =>
                                      item.university_name
                                    }
                                    getOptionValue={(item) => item.id}
                                  ></FormikSelect>
                                  <FormikErrorMessage
                                    name="university"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <hr className="mt-4 mb-1" />
                            <div className="row">
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                <label
                                  htmlFor="semester"
                                  className="form-label"
                                >
                                  {t("subjectsSemester")}
                                </label>
                                <div className="position-relative">
                                  <FormikMultiselect
                                    displayValue="semester"
                                    name="semester"
                                    placeholder={t("selectSemester")}
                                    className=" multi-select"
                                    options={semesterData}
                                    style={{
                                      multiselectContainer: {},
                                      searchBox: {
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        border: "1px solid #eff0f6",
                                      },
                                    }}
                                    onChange={(value) => {
                                      dispatch(
                                        getSubjectList({
                                          university_id: values?.university?.id,
                                          semester_ids: value
                                            ?.map((item) => item.id)
                                            .join(","),
                                        })
                                      );
                                      setFieldValue("subject", []);
                                    }}
                                  />

                                  <div className="apply-icon">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/book.svg"
                                      }
                                      alt="book"
                                    />
                                  </div>
                                </div>
                                <FormikErrorMessage
                                  name="semester"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                <label htmlFor="subject" className="form-label">
                                  {t("subject")}
                                </label>
                                <div className="position-relative">
                                  <FormikMultiselect
                                    displayValue="orignal_selected_subject"
                                    name="subject"
                                    placeholder={t("selectSubject")}
                                    className=" multi-select"
                                    options={subjectData}
                                    onChange={(selected) =>
                                      handleSubjectChange(selected)
                                    }
                                    optionValueDecorator={function noRefCheck(
                                      options
                                    ) {
                                      return options.toUpperCase();
                                    }}
                                    selectedValueDecorator={function noRefCheck(
                                      options
                                    ) {
                                      return options.toUpperCase();
                                    }}
                                    style={{
                                      multiselectContainer: {},
                                      searchBox: {
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        border: "1px solid #eff0f6",
                                      },
                                    }}
                                  />
                                  <div className="apply-icon">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/subject.svg"
                                      }
                                      alt="subject"
                                    />
                                  </div>
                                </div>
                                <FormikErrorMessage
                                  name="subject"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                <label
                                  htmlFor="language"
                                  className="form-label"
                                >
                                  {t("languageCap")}
                                </label>
                                <div className="position-relative">
                                  <FormikMultiselect
                                    // isObject={false}
                                    displayValue="language"
                                    name="language"
                                    placeholder={t("selectLanguages")}
                                    className=" multi-select"
                                    options={languageData}
                                    style={{
                                      multiselectContainer: {},
                                      searchBox: {
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        border: "1px solid #eff0f6",
                                      },
                                    }}
                                  />

                                  <div className="apply-icon">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/language.svg"
                                      }
                                      alt="language"
                                    />
                                  </div>
                                </div>
                                <FormikErrorMessage
                                  name="language"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            </div>

                            {values?.subject?.length > 0 && (
                              <FieldArray
                                name="subject"
                                render={(arrayHelpers) => (
                                  <div className="row">
                                    {values?.subject?.map((subject, index) => (
                                      <div
                                        key={index}
                                        className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px"
                                      >
                                        <label
                                          htmlFor={`subject.${index}.grade`}
                                          className="form-label"
                                        >
                                          {subject?.orignal_selected_subject?.toUpperCase()}{" "}
                                          {t("grade")}
                                        </label>
                                        <Field
                                          name={`subject.${index}.grade`}
                                          type="number"
                                          className="form-control apply-input"
                                        />
                                        {/* <FormikErrorMessage
                                        name={`subject.${index}.grade`}
                                        component="div"
                                        className="error-message"
                                      /> */}
                                        {errors?.subject?.[index]?.grade &&
                                          submitCount > 0 && (
                                            <div className="error-message">
                                              {errors?.subject?.[index]?.grade}
                                            </div>
                                          )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              />
                            )}

                            <div className="row">
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                <label
                                  htmlFor="experiance"
                                  className="form-label"
                                >
                                  {t("experianceInYear")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                    type="number"
                                    className="form-control apply-input"
                                    id="experiance"
                                    name="experiance"
                                  />
                                </div>
                                <FormikErrorMessage
                                  name="experiance"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-30px">
                                <label
                                  htmlFor="tutor_response"
                                  className="form-label"
                                >
                                  {t("tutorResponseTime")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                    type="text"
                                    className="form-control apply-input"
                                    id="tutor_response"
                                    name="tutor_response"
                                  />
                                </div>
                                <FormikErrorMessage
                                  name="tutor_response"
                                  component="div"
                                  className="error-message"
                                />
                              </div>

                              <div className="col-12 mt-30px">
                                <div className="apply-to-radio position-relative">
                                  <label htmlFor="teach" className="form-label">
                                    {t("availableToTeach")}
                                  </label>
                                  <div className="radio-on-off-main">
                                    <div>
                                      <Field
                                        type="radio"
                                        id="test1"
                                        name="teach"
                                        value="Online"
                                      />
                                      <label htmlFor="test1">
                                        {t("online")}
                                      </label>
                                    </div>
                                    <div>
                                      <Field
                                        type="radio"
                                        id="test2"
                                        name="teach"
                                        value="Offline"
                                      />
                                      <label htmlFor="test2">
                                        {t("offline")}
                                      </label>
                                    </div>
                                    <div>
                                      <Field
                                        type="radio"
                                        id="test3"
                                        name="teach"
                                        value="Online & Offline"
                                      />
                                      <label htmlFor="test3">
                                        {t("onlineAndOffline")}
                                      </label>
                                    </div>
                                  </div>
                                  <FormikErrorMessage
                                    name="availableToTeach"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>

                              <div className="col-12 mt-30px">
                                <div className="position-relative">
                                  <label
                                    htmlFor="address"
                                    className="form-label"
                                  >
                                    {t("address")}
                                  </label>
                                  <div className="apply-textarea">
                                    <Field
                                      name="address"
                                      id="address"
                                      as="textarea"
                                      cols={30}
                                      rows={3}
                                      className="form-control apply-input"
                                    />
                                    <FormikErrorMessage
                                      name="address"
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 mt-30px">
                                <label
                                  htmlFor="avs_details"
                                  className="form-label"
                                >
                                  {t("avsDetails")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                    type="text"
                                    className="form-control apply-input"
                                    id="avs_details"
                                    name="avs_details"
                                  />
                                  <div className="apply-icon">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/subject.svg"
                                      }
                                      alt="subject"
                                    />
                                  </div>
                                </div>
                                <FormikErrorMessage
                                  name="avs_details"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <div className="col-12 mt-30px">
                                <div className="position-relative">
                                  <label
                                    htmlFor="past_experience"
                                    className="form-label"
                                  >
                                    {t("pastExpIfAny")}
                                  </label>
                                  <div className="apply-textarea">
                                    <Field
                                      as="textarea"
                                      name="past_experience"
                                      placeholder="Write if you have any past Experience......."
                                      cols={30}
                                      rows={3}
                                    />
                                  </div>
                                  <FormikErrorMessage
                                    name="past_experience"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                              <div className="col-12 mt-30px">
                                <div className="position-relative">
                                  <label htmlFor="bio" className="form-label">
                                    {t("bio")}
                                  </label>
                                  <div className="apply-textarea">
                                    <Field
                                      as="textarea"
                                      name="bio"
                                      placeholder="Write if you have any past Experience......."
                                      cols={30}
                                      rows={3}
                                    />
                                  </div>
                                  <FormikErrorMessage
                                    name="bio"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                              <hr className="my-4" />
                              <div className="row">
                                <div className="col-12">
                                  <div className="tutor-dash-header">
                                    <h2 className="personal-text border-0">
                                      {t("updateYourDocuments")}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-25px">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3 mb-md-0">
                                  <div className="position-relative">
                                    <label
                                      htmlFor="field2"
                                      className="form-label"
                                    >
                                      {t("identityDocument")}
                                    </label>
                                    <div className="cloud cloud_uplod">
                                      {["jpeg", "jpg", "png"].includes(
                                        getFileTypeFromS3SignedURL(
                                          values?.identity
                                        )
                                      ) ? (
                                        <img
                                          src={
                                            values?.identityChange
                                              ? URL.createObjectURL(
                                                  values?.identityDocumentFile
                                                )
                                              : values?.identity
                                          }
                                          className="pcard"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons8-pdf-60.png"
                                          }
                                          className="pcard"
                                        />
                                      )}
                                      <div className="doc_upload">
                                        <input
                                          type="file"
                                          className="form-control  apply-input-file h-100"
                                          // defaultValue="Select Subject"
                                          id="identityDocument"
                                          name="identityDocument"
                                          onChange={handleIdentityDocument}
                                        />
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/cloud.svg"
                                          }
                                          alt="cloud"
                                          className="cloud-img"
                                        />
                                        <span className="upload-text">
                                          {t("uploadFile")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                  <form action="#">
                                    <div className="position-relative">
                                      <label
                                        htmlFor="field2"
                                        className="form-label"
                                      >
                                        {t("transcriptDocument")}
                                      </label>
                                      <div className="cloud cloud_uplod">
                                        {["jpeg", "jpg", "png", "pdf"].includes(
                                          getFileTypeFromS3SignedURL(
                                            values?.transcript
                                          )
                                        ) ? (
                                          <img
                                            src={values?.transcript}
                                            className="pcard"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons8-pdf-60.png"
                                            }
                                            className="pcard"
                                          />
                                        )}
                                        <div className="doc_upload">
                                          <input
                                            type="file"
                                            className="form-control  apply-input-file h-100"
                                            // defaultValue="Select Subject"
                                            id="gradeTranscript"
                                            name="gradeTranscript"
                                            onChange={
                                              handleGradeTranscriptDocument
                                            }
                                          />
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/cloud.svg"
                                            }
                                            alt="cloud"
                                            className="cloud-img"
                                          />
                                          <span className="upload-text">
                                            {t("updateFile")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <hr className="my-4" />
                              <h3 className="personal-text border-0">
                                {t("selectMeetingPlatform")}
                              </h3>
                              <div
                                className="accordion mt-25px"
                                id="meetingAccordion"
                              >
                                {/* Zoom Platform */}
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingZoom"
                                  >
                                    <button
                                      className={`accordion-button ${
                                        values.platform === "zoom"
                                          ? ""
                                          : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() =>
                                        handlePlatformChange(
                                          "zoom",
                                          setFieldValue
                                        )
                                      }
                                      aria-expanded={values.platform === "zoom"}
                                    >
                                      {t("zoom")}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseZoom"
                                    className={`accordion-collapse collapse ${
                                      values.platform === "zoom" ? "show" : ""
                                    }`}
                                    aria-labelledby="headingZoom"
                                    data-bs-parent="#meetingAccordion"
                                  >
                                    <div className="accordion-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <label className="form-label">
                                            {t("zoomMeetingLink")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="zoomLink"
                                            className={`form-control apply-input ${
                                              errors.zoomLink
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="zoomLink"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                        <div className="col-md-6 mt-30px">
                                          <label className="form-label">
                                            {t("zoomMeetingId")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="zoomMeetingId"
                                            className={`form-control apply-input ${
                                              errors.zoomMeetingId
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="zoomMeetingId"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                        <div className="col-md-6 mt-30px">
                                          <label className="form-label">
                                            {t("zoomMeetingPassword")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="zoomPassword"
                                            className={`form-control apply-input ${
                                              errors.zoomPassword
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="zoomPassword"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Google Meet Platform */}
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingGoogleMeet"
                                  >
                                    <button
                                      className={`accordion-button ${
                                        values.platform === "googleMeet"
                                          ? ""
                                          : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() =>
                                        handlePlatformChange(
                                          "googleMeet",
                                          setFieldValue
                                        )
                                      }
                                      aria-expanded={
                                        values.platform === "googleMeet"
                                      }
                                    >
                                      {t("googleMeet")}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseGoogleMeet"
                                    className={`accordion-collapse collapse ${
                                      values.platform === "googleMeet"
                                        ? "show"
                                        : ""
                                    }`}
                                    aria-labelledby="headingGoogleMeet"
                                    data-bs-parent="#meetingAccordion"
                                  >
                                    <div className="accordion-body">
                                      <label htmlFor="googleMeetLink">
                                        {t("googleMeetLink")}
                                      </label>
                                      <Field
                                        type="text"
                                        name="googleMeetLink"
                                        className={`form-control ${
                                          errors.googleMeetLink
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      />
                                      <FormikErrorMessage
                                        name="googleMeetLink"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Teams Platform */}
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTeams"
                                  >
                                    <button
                                      className={`accordion-button ${
                                        values.platform === "teams"
                                          ? ""
                                          : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() =>
                                        handlePlatformChange(
                                          "teams",
                                          setFieldValue
                                        )
                                      }
                                      aria-expanded={
                                        values.platform === "teams"
                                      }
                                    >
                                      {t("microsoftTeams")}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseTeams"
                                    className={`accordion-collapse collapse ${
                                      values.platform === "teams" ? "show" : ""
                                    }`}
                                    aria-labelledby="headingTeams"
                                    data-bs-parent="#meetingAccordion"
                                  >
                                    <div className="accordion-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <label className="form-label">
                                            {t("teamsMeetingLink")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="teamsLink"
                                            className={`form-control apply-input ${
                                              errors.teamsLink
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="teamsLink"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                        <div className="col-md-6 mt-30px">
                                          <label className="form-label">
                                            {t("teamsMeetingId")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="teamsIds"
                                            className={`form-control apply-input ${
                                              errors.teamsIds
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="teamsIds"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                        <div className="col-md-6 mt-30px">
                                          <label className="form-label">
                                            {t("teamMeetingPassword")}
                                          </label>
                                          <Field
                                            type="text"
                                            name="teamsPassword"
                                            className={`form-control apply-input ${
                                              errors.teamsPassword
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                          <FormikErrorMessage
                                            name="teamsPassword"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <FormikErrorMessage
                                name="platform"
                                component="div"
                                className="error-message"
                              />
                              <div className="back-next-between-btn-div">
                                <button
                                  type="submit"
                                  className="apply-form-next-btn border-8px"
                                >
                                  {t("updateBtn")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            {/*?php include 'inc/dashboard-footer.php'; ?*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default TutorEditProfile;
